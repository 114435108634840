import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUserAddress } from '../../redux/actions/userAddressesAction';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

const AddAddressHook = () => {
    const toast = useToast()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [alias, setAlias] = useState('')
    const [detalis, setDetalis] = useState('')
    const [phone, setPhone] = useState('')
    const [loading, setLoading] = useState(true)


    const onChangeAlias = (event) => {
        event.persist();
        setAlias(event.target.value)
    }

    const onChangeDetalis = (event) => {
        event.persist();
        setDetalis(event.target.value)

    }

    const onChangePhone = (event) => {
        event.persist();
        setPhone(event.target.value)

    }
    const onSubmit = async () => {
        if (alias === "" || detalis === "" || phone === "") {
            toast({
                title:"please fill all fields",
                status:'error',
                duration:3000,
                isClosable:true
              })
            return
        }
        setLoading(true)
        await dispatch(addUserAddress({
            alias: alias,
            details: detalis,
            phone: phone,
            city: '',
            postalCode: ""
        }))
        setLoading(false)
    }
    const res = useSelector(state => state.userAddressesReducer.addUserAddress)

    useEffect(() => {

        if (loading === false) {
            if (res && res.status === 200) {
                toast({
                    title:" added successfully",
                    status:'success',
                    duration:3000,
                    isClosable:true
                  })
                setTimeout(() => {
                    navigate('/user/address')
                }, 1000);


            } else {
                toast({
                    title:"there's problem ",
                    status:'error',
                    duration:3000,
                    isClosable:true
                  })
            }

        }

    }, [loading])



    return [alias, detalis, phone, onChangeAlias, onChangeDetalis, onChangePhone, onSubmit]
}

export default AddAddressHook