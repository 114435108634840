import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createNewUser } from '../../redux/actions/authAction';
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react';
const RegisterHook = () => {
    const toast = useToast()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(true)

    const onChangeName = (e) => {
        setName(e.target.value)
    }
    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }
    const onChangePhone = (e) => {
        setPhone(e.target.value)
    }
    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }
    const onChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }

    const validationValues = () => {
        if (name === "") {
            toast({
                title:' please enter you name',
                status:'error',
                duration:3000,
                isClosable:true 
                })
            return;
        }
        if (phone.length <= 6) {
            console.log({ phone : phone.length})
            toast({
                title:' please enter your phone number',
                status:'error',
                duration:3000,
                isClosable:true 
                })
            return;
        }
        if (password != confirmPassword) {
            toast({
                title:'please confirm your password ',
                status:'error',
                duration:3000,
                isClosable:true 
                })
            return;
        }

    }

    const res = useSelector(state => state.authReducer.createUser)

    //save data
    const OnSubmit = async (e) => {
        e.preventDefault()
        validationValues();
        setLoading(true)
        await dispatch(createNewUser({
            name,
            email,
            password,
            passwordConfirm: confirmPassword,
            phone
        }))
        setLoading(false)
    }

    useEffect(() => {
        if (loading === false) {
            if (res) {
                console.log({res})
                if (res.data.token) {
                    localStorage.setItem("token", res.data.token)
                    toast({
                        title:'sign up successfully',
                        status:'success',
                        duration:3000,
                        isClosable:true 
                        })
                    
                }

                if (res.data.errors) {
                    if (res.data.errors[0].msg === "E-mail already in use")
                    toast({
                        title:' this email already signup',
                        status:'success',
                        duration:3000,
                        isClosable:true 
                        })
                }
                if (res.data.errors) {
                    if (res.data.errors[0].msg === "accept only lebanon phone numbers")
                    toast({
                        title:'accept only lebanon number',
                        status:'error',
                        duration:3000,
                        isClosable:true 
                        })
                }

                if (res.data.errors) {
                    if (res.data.errors[0].msg === "must be at least 6 chars")
                    toast({
                        title:' at least 6 number to submit your password  ',
                        status:'error',
                        duration:3000,
                        isClosable:true 
                        })
                }


            }
        }
    }, [loading])

    return [name, email, phone, password, confirmPassword, loading, onChangeName, onChangeEmail, onChangePhone, onChangePassword, onChangeConfirmPassword, OnSubmit]
}

export default RegisterHook