import { Box, Circle, Flex, IconButton, Image, Input, Text } from '@chakra-ui/react'
import React from 'react'
import {DeleteIcon} from '@chakra-ui/icons'
import Logo from '../../images/eco-bag-vector-icon-removebg-preview.png'
import DeleteCartHook from '../../hook/cart/delete-cart-hook'
const CartItem = ({ item }) => {

  const [handelDeleteCart, show, handleClose, handleShow, handelDeleteItem, itemCount, onChangeCount, handeleUpdateCart] = DeleteCartHook(item)

  return (
    <Box w='100%' boxShadow={'0 0 5px rgba(0,0,0,0.2)'} borderRadius={'15px'}  mb='15px' display={'flex'}>
        <Box p={"5px"} h={{lg:'190px' , md:'150px', base:"120px"}} w={{lg:'170px', md:'130px' , sm:'120px',base:'30%'}}>
        <Image w="100%" h="100%" alignSelf={'center'}  src={item?.product?.imageCover || Logo}/>
        </Box>
        <Flex  justifyContent={'space-between'} p='12px' w={{sm:'100%' ,base:'70%'}}>
            <Box display={'flex'} flexDir={'column'} gap='4px'>
                <Text fontWeight={'bold'} fontSize={'24px'} opacity='.7' mb='10px'>{item?.product?.category?.name || ""}</Text>
            
                <Text fontWeight={'bold'} mb='5px'>Brand : {item?.product?.brand?.name || ""} </Text>
                {

              item.color === "" ? null : (
                <Circle boxShadow={'0 0 5px rgba(0,0,0,0.5)'} w={{lg:'30px' , md:'25px', sm:'25px',base:'20px'}} h={{lg:'30px' , md:'25px',sm:'25px',base:'20px'}} background={`${item.color}`} mb='7px'></Circle>
              
              )
            }
                   <Box>
                    <Text fontWeight={'semibold'} mr='5px' display={'inline'}>Quantity : </Text>
                    <Input 
                    value={itemCount}
                    onChange={onChangeCount}
                    variant={'unstyled'} 
                    border={'3px solid rgba(0,0,0,0.1)'} 
                    p='4.5px 16px' fontWeight={'semibold'}
                    opacity='.8' 
                    type="number" 
                    w='50px' 
                    h={{sm:'40px' , base:'30px'}} 
                    fontSize={{base:'10px'}}/>
                    </Box> 
                
            </Box>
            <Box padding={'0'} display={'flex'} justifyContent={'space-between'} flexDir='column' alignItems={'center'}>
                <IconButton onClick={handelDeleteItem} icon={<DeleteIcon/>}/>
                <Text fontWeight='600'>{item.price || 0}$</Text>
            </Box>
        </Flex>
    </Box>
  )
}

export default CartItem