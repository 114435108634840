import { Box, Flex, Image, Text} from '@chakra-ui/react'
import Logo from '../../images/eco-bag-vector-icon-removebg-preview.png'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPromo } from '../../redux/actions/promoAction'

const DiscountSection = () => {
const res = useSelector(state => state.promoReducer.promo)


  const dispatch = useDispatch()
useEffect(() => {
  dispatch(getAllPromo())
}, [])
  return (
    <>
    {
      res?.data?.length > 0 ? (
          res.data.map(e=>(
<Box key={e._id} w='100%' boxShadow={'0 0 7px rgba(0,0,0,0.5)'}  borderRadius={'20px'} mb='40px'>
        <Flex justify={'space-between'} alignItems='center' mx={{sm :'50px' , lg:'100px', base:'30px'}} py='5'>
            <Image maxW={{base:'80px',sm:'200px'}} src={e.image} />
            <Text fontSize={{sm:'30px' , base:'15px'}} fontWeight='semibold'>{e.name}</Text>
        </Flex>
    </Box>
          ))
        


      ):(null)
    }
    </>
   
  )
}

export default DiscountSection