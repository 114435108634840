import { Box, IconButton, Text, useDisclosure, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import {SettingsIcon} from '@chakra-ui/icons'
import UserSideBarSmallScreen from './UserSideBarSmallScreen'

function UserSideBar() {
    const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box>
        <Box w='100%' mb='20px'>
    <IconButton icon={<SettingsIcon/> } onClick={onOpen} display={{base:'block' , md:'none'}}/>
    </Box>
    <UserSideBarSmallScreen isOpen={isOpen} onClose={onClose}/>
    <VStack display={{base:'none' , md:'block'}} h='600px' p='20px'   borderRadius='5px' boxShadow={'0 0 7px rgba(0,0,0,0.4)'}>
        <Link to='/user/allorders' className='link' style={{width:'100%'}} >
            <Text  borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Order Management</Text>
        </Link>
        <Link to='/user/favoriteproducts' className='link' style={{width:'100%'}} >
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Favorite Products</Text>
        </Link>
        <Link to='/user/address' className='link' style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4'  fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Address</Text>
        </Link>
        <Link to='/user/profile' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Profile</Text>
        </Link>
    </VStack>
    </Box>
  )
}

export default UserSideBar