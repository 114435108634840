import {  ADD_SLIDER, DELETE_SLIDER, GET_ALL_SLIDER, GET_ERROR } from "../types"

const initial = {
    slider:[],
    deleteSlider:[],
    createSLider:[],
    loading:true
}

const sliderReducer = (state = initial , action) =>{
    switch(action.type){
        case GET_ALL_SLIDER :
            return {
                ...state,
                slider:action.payload,
                loading:false
            }
            
  
        case ADD_SLIDER :
            return {
                createSLider:action.payload,
                loading:false
            }

        case DELETE_SLIDER :
            return {
                deleteSlider:action.payload,
                loading:false
            }

        case GET_ERROR :
            return {
                    error:action.payload,
                    loading:true
            }

        default : return state
    }
}

export default sliderReducer