import React from 'react'
import {VStack} from '@chakra-ui/react'
import CategoryHeader from '../../components/category/CategoryHeader'
import ProductDetail from '../../components/product/ProductDetail'
import Rate from '../../components/Rate/Rate'
import Product from '../../components/product/Product'
import ViewProductsDetalisHook from '../../hook/products/view-products-detalis-hook'
import { useParams } from 'react-router-dom'
const ProductDetails = () => {
  const { id } = useParams();
  const [item, images, cat, brand, prod] = ViewProductsDetalisHook(id);
  console.log({prod , item , cat})
  if (prod)
      var items = prod.data?.slice(0, 4)

  if (item) {
      var rateAvg = item.ratingsAverage
      var rateQty = item.ratingsQuantity
  }
  return (
    <VStack mb='30px'>
        {/* <CategoryHeader/> */}
        <ProductDetail />
        <br/>
        <br/>
        <br/>
        <Rate rateAvg={rateAvg} rateQty={rateQty}/>
        <br/>
        <Product items={items} title='Product you may like'/>
    </VStack>
  )
}

export default ProductDetails