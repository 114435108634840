import {  ADD_PROMO_TOP, DELETE_PROMO_TOP, GET_ALL_PROMO_TOP, GET_ERROR } from "../types"

const initial = {
    promoTop:[],
    deletePromoTop:[],
    createPromoTop:[],
    loading:true
}

const promoTopReducer = (state = initial , action) =>{
    switch(action.type){
        case GET_ALL_PROMO_TOP :
            return {
                ...state,
                promoTop:action.payload,
                loading:false
            }
            
     
        
        case ADD_PROMO_TOP :
            return {
                createPromoTop:action.payload,
                loading:false
            }

        case DELETE_PROMO_TOP :
            return {
                deletePromoTop:action.payload,
                loading:false
            }

        case GET_ERROR :
            return {
                    error:action.payload,
                    loading:true
            }

        default : return state
    }
}

export default promoTopReducer