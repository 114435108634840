import {  SmallCloseIcon } from '@chakra-ui/icons'
import {  Box,  Flex, Heading, Text,   VStack } from '@chakra-ui/react'
import React from 'react'
import UserAddAddress from './UserAddAddress'
import UserEditAddress from './UserEditAddress'
import ViewAddressesHook from '../../hook/user/view-addresses-hook'
import { useDispatch } from 'react-redux'
import { deleteUserAddress } from '../../redux/actions/userAddressesAction'

const UserAddress = () => {
  const [res] = ViewAddressesHook()
  
  const dispatch = useDispatch()

    const handelDelete = async (id) => {

        await dispatch(deleteUserAddress(id))
      
        window.location.reload(false);
    }
  return (
    <VStack w='100%' display='start' spacing={'20px'}>
        <Heading>Address</Heading>
        {
                res.data  ? (res.data.map((item) => (
                  <Flex key={item._id}  display={{base:"column" , md:'row'}} p='5px 15px' w='100%' borderRadius={'10px'} boxShadow='0 0 5px rgba(0,0,0,0.5)'>
                  <Flex mb='20px' w='100%' fontSize={'23px'} alignItems='center' justifyContent={'space-between'}>
                      <Box cursor={"pointer"} onClick={()=>handelDelete(item._id)}><SmallCloseIcon/></Box>
                      <UserEditAddress id={item._id}/>
                  </Flex>
                  <Box mb='20px'>
                  <Text fontSize='19px' fontWeight={'700'} mb='13px'>{item.alias}</Text>
                  <Text fontSize='15px' fontWeight={'500'} opacity='.7'>{item.details}</Text>
                  </Box>
                  <Box mb='20px'>
                  <Text fontSize='19px' fontWeight={'700'} mb='13px'>Phone</Text>
                  <Text fontSize='15px' fontWeight={'500'} opacity='.7'>{item.phone}</Text>
                  </Box>
              </Flex>
                ))) : <h6>no address set</h6>
            }
        
        
        <UserAddAddress/>

    </VStack>
  )
}

export default UserAddress