import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react'
import {GiRoundStar} from 'react-icons/gi'
import ReactStars from 'react-rating-stars-component'
import React from 'react'
import DeleteRateHook from '../../hook/review/delete-rate-hook';
import EditRateHook from '../../hook/review/edit-rate-hook';
import { EditIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';

const RateItem = ({review}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isUser, handelDelete, handleShow, handleClose, showDelete] = DeleteRateHook(review);
  const [showEdit, handleCloseEdit, handleShowEdit, handelEdit, onChangeRateText, newRateText, OnChangeRateValue, newRateValue] = EditRateHook(review)
  const setting ={
    size:20,
    count:5,
    color:"#ECC94B",
    activeColor:"#ECC94B",
    value:newRateValue,
    isHalf:true,
    ally:true,
    emptyIcon:<BsStar/>,
    halfIcon:<BsStarHalf/>,
    filledIcon:<BsStarFill/>,
    onChange:newValue=>{
        OnChangeRateValue(newValue)
 
    }
}
  return (
    <Flex w='100%' justifyContent={'space-between'} borderBottom='1px solid rgba(0,0,0,0.2)' py='2'>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Review </ModalHeader>
          <ModalCloseButton />
          <ModalBody gap={'2px'}>
          <ReactStars {...setting} />
          
                    <input
                        onChange={onChangeRateText}
                        value={newRateText}
                        type="text"
                        className='font w-100'
                        style={{ border: 'none' }}
                    />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              Back
            </Button>
            <Button onClick={handelEdit} variant='ghost'> Edit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>




      
      <Box>
        <Flex>
            <Text fontSize={'20px'} fontWeight='semibold' mr='5px'>{review.user.name}</Text>
            <Flex fontWeight={'bold'} alignItems={'center'} color={'yellow.400'}>{review.rating}<GiRoundStar/></Flex>
        </Flex>
        <Text opacity={'.7'}>{review.review}</Text>
        </Box>
        <Box>
      {
            isUser === true ? (<Flex className='d-inline d-flex justify-content-end'>
          
              <Box><SmallCloseIcon cursor={'pointer'} onClick={handelDelete}/></Box>
              <Box><EditIcon cursor={'pointer'} onClick={onOpen}/></Box>

           </Flex>) : null
     }
      
      </Box>
    </Flex>

  )
}

export default RateItem