import { ADD_CATEGORY, DELETE_CATEGORY, GET_ALL_CATEGORY, GET_ERROR, GET_ONE_CATEGORY } from "../types"

const initial = {
    category:[],
    deleteCategory:[],
    createCategory:[],
    loading:true
}

const categoryReducer = (state = initial , action) =>{
    switch(action.type){
        case GET_ALL_CATEGORY :
            return {
                ...state,
                category:action.payload,
                loading:false
            }
            
        case GET_ONE_CATEGORY:
            return {
                oneCategory: action.payload,
                loading: false,
            }
        
        case ADD_CATEGORY :
            return {
                createCategory:action.payload,
                loading:false
            }

        case DELETE_CATEGORY :
            return {
                deleteCategory:action.payload,
                loading:false
            }

        case GET_ERROR :
            return {
                    error:action.payload,
                    loading:true
            }

        default : return state
    }
}

export default categoryReducer