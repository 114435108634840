import {  Box, Button, Container, Flex, HStack, IconButton, Image, Input, Text, useColorMode, useDisclosure } from '@chakra-ui/react'
import {FaSun , FaMoon} from 'react-icons/fa'
import {FiLogIn} from 'react-icons/fi'
import {BsCart4} from 'react-icons/bs'
import React, { useEffect, useState } from 'react'
import LogoBlack from '../../images/FinoBlack.png'
import LogoWhite from '../../images/FinoWhite.png'
import { Link } from 'react-router-dom'
import {ChevronDownIcon, HamburgerIcon} from '@chakra-ui/icons'
import NavBurger from './NavBurger'
import NavbarSearchHook from './../../hook/search/navbar-search-hook';
import GetAllUserCartHook from './../../hook/cart/get-all-user-cart-hook';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'


const NavBarLogin = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {colorMode , toggleColorMode}=useColorMode()
  const isDark = colorMode ==='dark'


  const [OnChangeSearch, searchWord] = NavbarSearchHook()
  let word = "";
  if (localStorage.getItem("searchWord") != null) word = localStorage.getItem("searchWord");

  const [user, setUser] = useState('');
  useEffect(() => {
      if (localStorage.getItem("user") != null)
          setUser(JSON.parse(localStorage.getItem("user")))
  }, [])

  const logOut = () => {
      localStorage.removeItem("user")
      localStorage.removeItem("token")
      setUser('')
  }
  const [itemsNum] = GetAllUserCartHook()


  return (
    <HStack w='100%' py='3' mb='30px' >
    <Container   maxW={{xl:'container.xl' , lg:'container.lg',  md:'container.md' , sm:'container.sm' , base:'400px'}}>
      <Flex gap={'25px'} alignItems={'center'}>
       <Link to='/' > <Image w={{md:'250px' , base:"800px"}} h='130px'src={isDark ?LogoWhite :LogoBlack}></Image></Link>
        <Input
        value={word}
        onChange={OnChangeSearch}
        type='search'
        placeholder='Search...'
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        >
        </Input>
        <Box  display={{base:'none', sm:'block'}} >
          {
            user != '' ? (
<Menu>
          
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
           {user.name} 
          </MenuButton>
          <MenuList>
            {
              user.role === "admin" ? (<MenuItem><Link to='/admin/allproducts' className='link' >Dashboard</Link></MenuItem>):(<MenuItem><Link to='/user/profile' className='link' >Profile</Link></MenuItem>)
            }
          
            
            <MenuItem onClick={logOut}>log out</MenuItem>
          </MenuList>
        </Menu>
            ):(
<Link to='/login' className='link' >
        <Button w='120px'   variant={'unstyled'}>
          <Flex alignItems={'center'}>
            <Text mr='4px'>Login</Text> 
            <FiLogIn/>
          </Flex>
        </Button>
        </Link>
            )
          }
        
        
        </Box>
        
        <Box display={{base:'none', sm:'block'}} >
        <Link to='/cart' className='link'>
        <Button >
          <Flex alignItems={'center'}>
            <Text mr='4px'>Cart</Text> 
            <BsCart4/>
          </Flex>
        </Button>
        </Link>
        </Box>
        <IconButton  isRound={true} icon={isDark?<FaSun/>:<FaMoon/>} onClick={toggleColorMode}></IconButton>
        <Box display={{base:'block' , md:'none'}} >
        <IconButton icon={<HamburgerIcon/>} onClick={onOpen}/>
      </Box>
      </Flex>
    </Container>
    <NavBurger isOpen={isOpen} onClose={onClose}/>
    </HStack>
  )
}

export default NavBarLogin