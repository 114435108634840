import { Box, Button, Heading, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react'
import React,{useState} from 'react'
import { Stack } from 'react-bootstrap'

const PaymentMethod = () => {
   const [value, setValue] = useState('1')
 
  return (
    <VStack spacing={'4'} w='100%'>
        <Heading w='100%'>Choose Your Payment Method</Heading>
        <Box w='100%' p={'40px 50px'} borderRadius={'10px'} boxShadow={'0 0 7px rgba(0,0,0,0.2)'}>
       
            
    
        </Box>
        <Box display={'flex'} justifyContent={'end'} w='100%' alignItems={'center'}>
            <Text fontSize={'18px'} fontWeight={'500'}>120$</Text>
            <Button w='150px' ml='20px' colorScheme={'yellow'}>Checkout</Button>
        </Box>
    </VStack>
  )
}

export default PaymentMethod