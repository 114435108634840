
import { Box, Button, Flex, Heading, Select, Stack, Text} from '@chakra-ui/react'
import React from 'react'
import CartItem from '../Cart/CartItem'

const OrderDetails = () => {
  return (
    <Box>
        <Heading mb='20px'>Order Details Number 23</Heading>
        <CartItem/>
        <CartItem/>
        <Box fontWeight={'500'} p='5' w='100' mt='20px' boxShadow={'0 0 5px rgba(0,0,0,0.2)'}> 
          <Text fontSize={'30px'} mb='15px'>Details User</Text> 
          <Stack spacing={'2'} mb='20px'>
            <Text fontSize={'18px'} opacity={'.8'}>Name : Kerolos Hany</Text>
            <Text fontSize={'18px'} opacity={'.8'}>Number : 01227332470</Text>
            <Text fontSize={'18px'} opacity={'.8'}>Email : kokoh2370@gmail.com</Text>
          </Stack> 
          <Text fontSize={'20px'} mb='20px'>Price : 80$</Text>
          <Flex>
            <Button colorScheme={'yellow'}>Save</Button>
            <Select  _focus={{border:'none'}} variant={'filled'} placeholder='order status'>
              <option  value='option1'>Pendding</option>
              <option value='option2'>Completed</option>
              <option value='option3'>Canceled</option>
            </Select>
          </Flex>
        </Box>
    </Box>
  )
}

export default OrderDetails