import useDeleteData from "../../hooks/useDeleteData"
import { useGetData } from "../../hooks/useGetData"
import { useInsertDataWithImage } from "../../hooks/useInsertData"
import { ADD_SLIDER, DELETE_SLIDER, GET_ALL_SLIDER, GET_ERROR } from "../types"





export const getAllSlider = (limit ,page)=> async(dispatch)=>{
    try {
        const res =await useGetData(`api/v1/sliders?limit=${limit}&page=${page}`)
        dispatch({
            type:GET_ALL_SLIDER,
            payload:res.data
        })
    } catch (error) {
        dispatch({
            type:GET_ERROR,
            payload:"Error : " + error.response.data
        })
    }
}

//get one category with


export const deleteSlider = (id) => async (dispatch) => {
    try {
        const response = await useDeleteData(`/api/v1/sliders/${id}`);
        dispatch({
            type: DELETE_SLIDER,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const addSlider = (data) => async(dispatch)=>{
    try{
        const res =await useInsertDataWithImage('api/v1/sliders' , data)
        
        dispatch({
            type: ADD_SLIDER,
            payload:res,
        })
    }catch(error){
        dispatch({
            type: GET_ERROR,
            payload:"Error : " + error.response.data
        })
    }
    
}