import {  Box, Button,  Circle,  Heading, HStack,  Input, Select, Textarea, VStack } from '@chakra-ui/react'
import React from 'react'
import MultiImageInput from 'react-multiple-image-input';
import add from '../../images/add.png'
import { CompactPicker } from 'react-color'
import AdminEditProductsHook from '../../hook/products/edit-products-hook';
const EditProduct = ({id}) => {
    
    const [CatID, BrandID, onChangeDesName, onChangeQty, onChangeColor, onChangePriceAfter, onChangePriceBefor, onChangeProdName, showColor, category, brand, priceAftr, images, setImages, onSelect, onRemove, options, handelChangeComplete, removeColor, onSeletCategory, handelSubmit, onSeletBrand, colors, priceBefore, qty, prodDescription, prodName] = AdminEditProductsHook(id)

    return (
        <VStack spacing={'20px'} display='start' w='100%'>
        <Heading >Add Product</Heading>
        <MultiImageInput
                        images={images}
                        setImages={setImages}
                        theme={"light"}
                        allowCrop={false}
                        max={4}
                    />
                    <HStack spacing={'10px'}>

{
    colors.length >= 1 ? (
        colors.map((color, index) => {
            return (
                <Box
                bgColor={color}
                w={'30px'}
                h='30px'
                borderRadius={'100%'}
                 key={index}
                    onClick={() => removeColor(color)}
                    className="color ms-2 border  mt-1"
                    ></Box>
            )
        })

    ) : null
}

<img onClick={onChangeColor} src={add} alt="" width="30px" height="35px" style={{ cursor: 'pointer' }} />
{
    showColor === true ? <CompactPicker onChangeComplete={handelChangeComplete} /> : null
}


</HStack>
          <Input
         value={prodName}
         onChange={onChangeProdName} 
          type='text'
        placeholder="name"
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        <Textarea
        value={prodDescription}
        onChange={onChangeDesName}
        placeholder='Description...'
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='6px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
       
        />
        <Input
         value={priceBefore}
         onChange={onChangePriceBefor}
        placeholder="price  "
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
         type='number'
         p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
    
        <Input
         value={qty}
         onChange={onChangeQty}
         type='number'
        placeholder="quantity"
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        <Select onChange={onSeletCategory} opacity='.8'  _focus={{border:'none'}} variant={'filled'}>
        <option value="0"> choose category</option>
        {
                            category.data ? (category.data.map((item, index) => {
                                return (
                                    <option key={index} value={item._id}>{item.name}</option>
                                )
                            })) : null

                        }
        </Select>
        <Select onChange={onSeletBrand} opacity='.8'  _focus={{border:'none'}} variant={'filled'}>
        <option value="0"> choose brand</option>
        {
                          brand.data ? (brand.data.map((item, index) => {
                            return (
                                <option key={index} value={item._id}>{item.name}</option>
                            )
                        })) : null

                        }
        </Select>
       

        
          <Button onClick={handelSubmit} colorScheme={'yellow'}>Save Edits</Button>
    </VStack>
  )
}

export default EditProduct