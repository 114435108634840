import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createNewUser, forgetPassword, loginUser } from '../../redux/actions/authAction';
import { useNavigate } from 'react-router-dom'
import { createReview } from './../../redux/actions/reviewAction';
import { useToast } from '@chakra-ui/react';

const AddRateHook = (id) => {
    const toast= useToast()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rateText, setRateText] = useState('')
    const [rateValue, setRateValue] = useState(0)

    const [loading, setLoading] = useState(true)


    const OnChangeRateText = (e) => {
        setRateText(e.target.value)
    }
    const OnChangeRateValue = (val) => {
        setRateValue(val)
    }
    var user = ""
    if (localStorage.getItem("user") != null)
        user = JSON.parse(localStorage.getItem("user"))


    ///when save rate 
    const onSubmit = async () => {
        if (rateText === "") {
            toast({
                title:'please write comment',
                status:'error',
                duration:3000,
                isClosable:true 
                })
            return
        }
        setLoading(true)
        await dispatch(createReview(id, {
            review: rateText,
            rating: rateValue
        }))
        setLoading(false)
    }

    const res = useSelector(state => state.reviewReducer.createView)

    useEffect(() => {
        if (loading === false) {
            if (res) {
                console.log(res)
                if (res.status && res.status === 403) {
                    toast({
                        title:'your an admin can not comment ',
                        status:'error',
                        duration:3000,
                        isClosable:true 
                        })
                } else if (res.data.errors && res.data.errors[0].msg === "You already added review on this product") {
                    toast({
                        title:'  your are already comment with product before',
                        status:'error',
                        duration:3000,
                        isClosable:true 
                        })
                } else if (res.status && res.status === 201) {
                    toast({
                        title:' comment successfully',
                        status:'success',
                        duration:3000,
                        isClosable:true 
                        })
                    setTimeout(() => {
                        window.location.reload(false)
                    }, 1000);
                }
            }
        }
    }, [loading])

    return [OnChangeRateText, OnChangeRateValue, rateText, rateValue, user, onSubmit]
}

export default AddRateHook