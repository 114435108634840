import { Image, VStack } from '@chakra-ui/react'
import React from 'react'

const BrandCard = ({img}) => {
 
  return (
    <VStack  boxShadow={'0 0 3px yellow,0 0 7px yellow,0 0 15px yellow'} borderRadius='10px' p='3' _hover={{transform:'translateY(-5px)'}} transition={'ease-out all .3s'}>
      <Image w={{sm:'150px' , base:'70px'}} h={{sm:'150px' , base:'70px'}} src={img}/>
    </VStack>
  )
}

export default BrandCard