import  { useEffect } from 'react'
import { useSelector , useDispatch } from 'react-redux'
import { getAllCategory } from '../../redux/actions/cateogryAction'
const AllCategoryHook = () => {
    const res = useSelector(state => state.allCategory.category)
  const loading = useSelector(state => state.allCategory.loading)

  const onPage = (num)=>{
    dispatch(getAllCategory(12,num))
  }
  let pageCount = 0
  if(res?.paginationResult){
    pageCount = res.paginationResult.numberOfPages
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllCategory(12))
  }, [dispatch])
  return [res , loading, onPage ,pageCount]
}

export default AllCategoryHook