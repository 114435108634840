import {  SimpleGrid, Spinner, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import CategoryCard from '../../components/category/CategoryCard'
import Pagination from '../../components/Utils/Pagination'
import AllCategoryHook from '../../hook/cateogry/all-category'


const AllCategory = () => {
 const [res , loading, onPage ,pageCount]  = AllCategoryHook()
  
  return (
    <VStack w='100%' spacing={'40px'}  mb='60px'>
    <Text w='100%' fontSize={'28px'} fontWeight={'semibold'} mb='40px'>All Categories</Text>
    {loading? <Spinner size='lg'/>:(
      <SimpleGrid columns={{xl:[6] ,lg:[5] , md:[4] , sm:[3] , base:[2] }} w='100%' spacing={'60px'} >
    {res.data.length> 0 ?(res.data.map(category=>(
      <CategoryCard key={category._id}  img={category.image} title={category.name}/>
    ))):(<Text>no data</Text>)}
    </SimpleGrid>
    )}
    {
      pageCount > 1 ? 
      (
        <Pagination pageCount={pageCount?pageCount : 0} onPage={onPage}/>
      ):null
    }
    

    </VStack>
  )
}

export default AllCategory