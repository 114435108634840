import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import UserProfil from '../../components/user/UserProfil'
import UserSideBar from '../../components/user/UserSideBar'

const UserProfile = () => {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
  
    if (!user || user.role !== "user") {
      window.location.href = '/'; // Redirect to the home page
    }
  }, []);
  return (
    <Flex w='100%' flexDir={{base:'column' ,md:'row'}} justifyContent={'space-between'} mb='40px'>
        <Box w={{md:'25%' , base:'100%'}}>
                <UserSideBar/>
        </Box>
        <Box w={{md:'70%' , base:'100%'}}>
                <UserProfil/>
        </Box>
    </Flex>
  )
}

export default UserProfile