import useDeleteData from "../../hooks/useDeleteData";
import { useGetData } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { ADD_CATEGORY, DELETE_CATEGORY, GET_ALL_CATEGORY, GET_ERROR, GET_ONE_CATEGORY } from "../types";

export const getAllCategory = (limit ,page)=> async(dispatch)=>{
    try {
        const res =await useGetData(`api/v1/categories?limit=${limit}&page=${page}`)
        dispatch({
            type:GET_ALL_CATEGORY,
            payload:res.data
        })
    } catch (error) {
        dispatch({
            type:GET_ERROR,
            payload:"Error : " + error.response.data
        })
    }
}

//get one category with
export const getOneCategory = (id) => async (dispatch) => {
    try {
        const response = await useGetData(`/api/v1/categories/${id}`);
console.log(response)
        dispatch({
            type: GET_ONE_CATEGORY,
            payload: response.data,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const deleteCategory = (id) => async (dispatch) => {
    try {
        const response = await useDeleteData(`/api/v1/categories/${id}`);

        dispatch({
            type: DELETE_CATEGORY,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const addCategory = (data) => async(dispatch)=>{
    try{
        const res =await useInsertDataWithImage('api/v1/categories' , data)
        dispatch({
            type: ADD_CATEGORY,
            payload:res,
        })
    }catch(error){
        dispatch({
            type: GET_ERROR,
            payload:"Error : " + error.response.data
        })
    }
    
}