
import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({pageCount, onPage}) => {

const handlePage = (data)=>{
    onPage(data.selected +1)
}

return (
    <ReactPaginate
    breakLabel='...'
    nextLabel='next >'
    onPageChange={handlePage}
    marginPagesDisplayed={2}
    pageRangeDisplayed={2}
    pageCount={pageCount}
    previousLabel='< previous'
    containerClassName='pagination justify-content-center p-4'
    pageClassName='page-item'
    pageLinkClassName='page-link'
    previousClassName='page-item'
    previousLinkClassName='page-link'
    nextClassName='page-item'
    nextLinkClassName='page-link'
    breakClassName='page-item'
    breakLinkClassName='page-link'
    activeClassName='active'
    />
  )
}

export default Pagination