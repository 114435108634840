import React, { useEffect } from 'react'
import {Box, Flex, Heading} from '@chakra-ui/react'
import CartItem from '../../components/Cart/CartItem'
import CartCheckout from '../../components/Cart/CartCheckout'
import GetAllUserCartHook from '../../hook/cart/get-all-user-cart-hook'

const Cart = () => {
  const [cartID ,  itemsNum, cartItems, totalCartPrice, couponNameRes, totalCartPriceAfterDiscount] = GetAllUserCartHook()
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
  
    if (!user || user.role !== "user") {
      window.location.href = '/'; // Redirect to the home page
    }
  }, []);
  return (
    <Box >
        <Heading mb='20px'> Cart </Heading>
        <Box fontWeight={'bold'} mb='18px'> {itemsNum} Items </Box>
        <Flex w='100%'flexDir={{md:'row' , base:'column'}} justifyContent='space-between'>
            
            <Box w={{lg:'73%',md:'67%'}}>
            {
                        cartItems.length >= 1 ? (cartItems.map((item, index) => {
                            return (<CartItem key={index} item={item} />)
                        })) : <h6>   no product in the cart </h6>
                    }
                
            </Box>
            <Box w={{lg:'25%',md:'30%'}}>
                <CartCheckout id={cartID} couponNameRes={couponNameRes} totalCartPriceAfterDiscount={totalCartPriceAfterDiscount} totalCartPrice={totalCartPrice}/>
            </Box>
        </Flex>
    </Box>
  )
}

export default Cart