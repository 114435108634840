import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Textarea, useDisclosure, VStack } from '@chakra-ui/react'
import React from 'react'
import AddAddressHook from '../../hook/user/add-address-hook'

const UserAddAddress = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [alias, detalis, phone, onChangeAlias, onChangeDetalis, onChangePhone, onSubmit] = AddAddressHook()
  return (
    <VStack>
    <Button onClick={onOpen} colorScheme={'yellow'} key={'AddAdress'} w='100%'>Add Address</Button>
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{base:'xs' , md:'md'}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <VStack spacing={'17px'} display='start'>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>Place (Home or Work)</FormLabel>
                <Input 
                value={alias}
                onChange={onChangeAlias}
                type='text'
                placeholder='Enter Your New Place'
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>Address</FormLabel>
                <Textarea
                value={detalis}
                onChange={onChangeDetalis}
                  placeholder='Enter your Address Details'
                  w='100%'
                  variant={'unstyled'}
                  border={'3px solid rgba(0,0,0,0.1)'}
                  p='6px 16px'
                  fontSize={'15px'}
                  fontWeight={'semibold'}
                  opacity='.8'
                  
                  />
            </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>Phone</FormLabel>
                <Input 
                value={phone}
                onChange={onChangePhone}
                type='text'
                placeholder='Enter Your New Phone'
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
              <Button w='100%' onClick={onSubmit} colorScheme={'yellow'}>Add</Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      </VStack>
  )
}

export default UserAddAddress