
import { Box, Button, Circle, Divider, Flex, Image, Text, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cancelOrder, completeOrder } from '../../redux/actions/orderAction'

const AllOrdersItem = ({item}) => {
  const toast = useToast()
const dispatch = useDispatch()
     const res = useSelector(state => state.orderReducer.completeOrder)
     const resCancel = useSelector(state => state.orderReducer.cancelOrder)
     const completeOrderButton = (id)=>{
        dispatch(completeOrder(id))
     }

     const cancelOrderButton = (id)=>{
      dispatch(cancelOrder(id))
     }

     useEffect(() => {
       if(res.status === 200){
        toast({
          title:'the order completed successfully  ',
          status:'success',
          duration:3000,
          isClosable:true 
          })
       }
     }, [ ])

     useEffect(() => {
      if(resCancel.status === 200){
       toast({
         title:'the order cancelled successfully  ',
         status:'success',
         duration:3000,
         isClosable:true 
         })
      }
    }, [ ])
     

  return (
    <Box p='12px' w='100%' boxShadow={'0 0 5px rgba(0,0,0,0.2)'} borderRadius={'15px'}  mb='15px' >
        <Text fontWeight={'semibold'} opacity='.7' mb='10px'>order number : {item.id}</Text>
        
        <Text fontWeight={'bold'} mb='5px'>name : {item.user.name}</Text>
        <Text fontWeight={'bold'} mb='5px'>phone : {item.user.phone}</Text>
        <Text fontWeight={'bold'} mb='5px'>alias : {item.shippingAddress.alias}</Text>
        <Text fontWeight={'bold'} mb='20px' >address details : {item.shippingAddress.details}</Text>
        
        

        {
          item.cartItems.map(pro=>(
            <Box key={pro._id}>
              <Divider mb={"20px"}/>
            <Box w='100%' gap={'15px'} display={'flex'} alignItems={'center'}>
              
            <Image alignSelf={'center'} h={{lg:'190px' , md:'150px', base:"120px"}} w={{lg:'170px', md:'130px' , sm:'120px',base:'30%'}} src={pro.product.imageCover}/>
            
                <Box>
                    <Text fontSize={{lg:'19px',md:'15px'}}  fontWeight='bold'>{pro.product.title}</Text>
                    <Circle boxShadow={'0 0 5px rgba(0,0,0,0.5)'} w={{lg:'30px' , md:'25px', sm:'25px',base:'20px'}} h={{lg:'30px' , md:'25px',sm:'25px',base:'20px'}} background={pro.color} mb='7px'></Circle>
                        <Text fontWeight={'semibold'} mr='5px' display={'inline'}>Quantity : {pro.count}</Text>
                    <Text fontWeight={'bold'} mb='5px'>{pro.price}$</Text>
                      
                    
                </Box>
            
        </Box>
            </Box>
          ))
        }
          
          <Divider my={"20px"}/>
          <Text fontWeight={'bold'} mb='5px'>total price : {item.totalOrderPrice}$</Text>
        {
          (item.cancelled === true || item.isDelivered === true)  ? (
            null
            
     
          ):(<Flex gap={"10px"} my={"20px"}>
          <Button variant={'solid'} onClick={()=>completeOrderButton(item._id)} colorScheme='green'>complete</Button>
          <Button variant={'solid'} colorScheme='red' onClick={()=>cancelOrderButton(item._id)}>cancel</Button>
        </Flex>)
        }
    </Box>
  )
}

export default AllOrdersItem