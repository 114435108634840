import { Button, Flex, Text } from '@chakra-ui/react'

import React from 'react'
import { Link } from 'react-router-dom'

const SubTittle = ({title, btntitle,pathText}) => {
  return (
    <Flex justifyContent={'space-between'} w='100%' mb='30px'>
        <Text fontSize={'20px'} fontWeight={'semibold'}>{title}</Text>
        {btntitle?<Link to={`${pathText}`}> <Button colorScheme={'yellow'}>{btntitle}</Button></Link>:null}
    </Flex>
  )
}

export default SubTittle