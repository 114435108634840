import useDeleteData from "../../hooks/useDeleteData"
import { useGetData } from "../../hooks/useGetData"
import { useInsertDataWithImage } from "../../hooks/useInsertData"
import { ADD_BRAND, DELETE_BRAND, GET_ALL_BRAND, GET_ERROR, GET_ONE_BRAND } from "../types"


export const getAllBrand = (limit,page)=> async(dispatch)=>{
    try{
        const res = await useGetData(`/api/v1/brands?limit=${limit}&page=${page}`)
        dispatch({
            type: GET_ALL_BRAND,
            payload:res.data,
        })
    }catch(error){
        dispatch({
            type:GET_ERROR,
            payload:"Error : " + error.response.data
        })
    }
} 

//get one Brand
export const getOneBrand = (id) => async (dispatch) => {
    try {
        const response = await useGetData(`/api/v1/brands/${id}`);

        dispatch({
            type: GET_ONE_BRAND,
            payload: response.data,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const deleteBrand = (id) => async (dispatch) => {
    try {
        const response = await useDeleteData(`/api/v1/brands/${id}`);

        dispatch({
            type: DELETE_BRAND,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const addBrand = (data)=> async(dispatch)=>{
    try {
        const res = await useInsertDataWithImage('/api/v1/brands' , data)
        dispatch({
            type :ADD_BRAND,
            payload:res,
        })
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: "Error : " + error.response.data
        })
    }
}