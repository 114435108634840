import { VStack } from '@chakra-ui/react'
import React from 'react'
import { Container } from '@chakra-ui/react'
import Slider from '../../components/Home/Slider'
import HomeCategory from '../../components/Home/HomeCategory'
import Product from '../../components/product/Product'
import DiscountSection from '../../components/Home/DiscountSection'
import Brand from '../../components/Brand/Brand'
import ViewHomeProductsHook from '../../hook/products/view-home-products-hook'


const Home = () => {
  const [items] = ViewHomeProductsHook();
  console.log({items})
  return (
    <VStack>
        <Container maxW={{xl:'container.xl' , lg:'container.lg',  md:'container.md' , sm:'container.sm' , base:'400px'}}>
            <Slider/>
            <HomeCategory/>
            <Product items={items} title='most saller' btntitle={'more'}/> 
            <DiscountSection/>
            <Product items={items} title='most rated' btntitle={'more'}/>
            <Brand title={'Brand'} btntitle={'more'}/>
        </Container>
    </VStack>
  )
}

export default Home