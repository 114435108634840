import { Container, useColorMode } from '@chakra-ui/react';
import React ,{useEffect, useState} from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Logo from '../../images/eco-bag-vector-icon-removebg-preview.png'
import { useDispatch, useSelector } from 'react-redux';
import { getAllSlider } from '../../redux/actions/sliderActions';
const Slider = () => {
    const {colorMode} =useColorMode()
    const isDark = colorMode === 'dark'
    const [index, setIndex] = useState(0);
    const res = useSelector(state => state.sliderReducer.slider)


  const dispatch = useDispatch()
  useEffect(() => {
    const get = async ()=>{

    await  dispatch(getAllSlider())
    }
   get()
  }, [dispatch])

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
  return (
    
    <Container mb='15px' maxW={{xl:'540px' ,sm:'500px' , base:'300px'}}>
      {
        res?.data?.length > 0 ? (
          <Carousel activeIndex={index} onSelect={handleSelect} variant={isDark ?"light" :"dark"}>
          {
            res?.data.length > 0 ?(
              res.data.map(e =>(
    
            <Carousel.Item key={e._id}> 
            <img
              className="d-block w-100"
              src={e.image}
              alt="First slide"
            />
            
          </Carousel.Item>
              ))
            ):(null)
          }
          
       
        </Carousel>
        ):(null)
      }
    
    </Container>
  )
}

export default Slider