import { Flex, Heading, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import UserOrderItem from './UserOrderItem'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrder } from '../../redux/actions/orderAction'
import Pagination from '../Utils/Pagination'

function UserAllOrder() {

  const res = useSelector(state => state.orderReducer.getAllOrder)
console.log({resAllOrder : res})
  const onPage = (num)=>{
    dispatch(getAllOrder(2,num))
  }
  let pageCount = 0
  if(res.paginationResult){
    pageCount = res.paginationResult.numberOfPages
  }
  const dispatch = useDispatch()
  useEffect(() => {
    
         dispatch(getAllOrder(2))
  
  }, [dispatch])
  
  return (
    <VStack w='100%' spacing={'20px'}>
        <Heading w='100%'>Hi  </Heading>
        { res?.data  ? (
                res.data.map(e=>(
                  <VStack key={e._id} p='5px' display='start' w='100%' spacing={'15px'} boxShadow={'0 0 5px rgba(0,0,0,0.2)'} borderRadius={'15px'}>
                <Text p='5px 10px' fontWeight='500' fontSize={{base:'17px' , md:'19px'}}>#{e.id}</Text>
                 {
                  e.cartItems.map((item , index)=>(
                    <UserOrderItem key={index} item={item}/>

                  ))
                 }
                 

                  <Flex w='100%'  p='5px 10px' justifyContent={'space-between'} alignItems='center'>
                      <Text fontWeight='500' fontSize={{base:'17px' , md:'19px'}}>order status: {e.isDelivered ? "done" : e.cancelled? "cancelled" :"pending"}</Text>
                      <Text fontWeight='500' fontSize={{base:'17px' , md:'19px'}}>{e.totalOrderPrice}$</Text>
                  </Flex>
                  </VStack>

                ))

        ):"no order"}
        {
      pageCount > 1 ? 
      (
        <Pagination pageCount={pageCount?pageCount : 0} onPage={onPage}/>
      ):null
    }
    
        
    </VStack>
  )
}

export default UserAllOrder