
import {  Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,  useDisclosure, VStack } from '@chakra-ui/react'
import React from 'react'

const UserEditProfile = ({handelSubmit, name, email, phone, onChangeName, onChangeEmail, onChangePhone}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <VStack>
        <Button onClick={onOpen} colorScheme={'yellow'} w='100%'>Edit</Button>
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={{base:'xs' , md:'md'}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Your Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={'17px'} display='start'>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>Name</FormLabel>
                <Input 
                value={name}
                onChange={onChangeName}
                type='text'
                placeholder='Enter Your New Name'
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>Phone</FormLabel>
                <Input 
                value={phone}
                onChange={onChangePhone}
                type='text'
                placeholder='Enter Your New Phone'
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>Email</FormLabel>
                <Input 
                value={email}
                onChange={onChangeEmail}
                type='email'
                placeholder='Enter Your New Email'
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
              <Button w='100%' onClick={handelSubmit} colorScheme={'yellow'}>Edit</Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  )
}

export default UserEditProfile