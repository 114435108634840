import { ADD_PROMO, DELETE_PROMO, GET_ALL_PROMO, GET_ERROR } from "../types"

const initial = {
    promo:[],
    deletePromo:[],
    createPromo:[],
    loading:true
}

const promoReducer = (state = initial , action) =>{
    switch(action.type){
        case GET_ALL_PROMO :
            return {
                ...state,
                promo:action.payload,
                loading:false
            }
            
     
        
        case ADD_PROMO :
            return {
                createPromo:action.payload,
                loading:false
            }

        case DELETE_PROMO :
            return {
                deletePromo:action.payload,
                loading:false
            }

        case GET_ERROR :
            return {
                    error:action.payload,
                    loading:true
            }

        default : return state
    }
}

export default promoReducer