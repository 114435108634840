import  { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import {  loginUser } from '../../redux/actions/authAction'
import { useToast } from '@chakra-ui/react';

const LoginHook = () => {
    const toast = useToast()

    const dispatch = useDispatch();


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(true)
    const [isPress, setIsPress] = useState(false)
    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setIsPress(true)
        setLoading(true)
        await dispatch(loginUser({
            email,
            password
        }))

        setLoading(false)
        setIsPress(false)
    }
    const res = useSelector(state => state.authReducer.loginUser)
    useEffect(() => {
        if (loading === false) {
            if (res) {
                console.log(res)
                if (res.data.token) {
                    localStorage.setItem("token", res.data.token)
                    localStorage.setItem("user", JSON.stringify(res.data.data))
                    toast({
                        title:'login successfully',
                        status:'success',
                        duration:3000,
                        isClosable:true 
                        })
                    setTimeout(() => {
                        window.location.href = "/"
                    }, 1500);
                } else {
                    localStorage.removeItem("token")
                    localStorage.removeItem("user")
                }

                if (res.data.message === "Incorrect email or password") {
                    localStorage.removeItem("token")
                    localStorage.removeItem("user")
                    toast({
                        title:'wrong  password or email',
                        status:'error',
                        duration:3000,
                        isClosable:true 
                        })
                }
                setLoading(true)
            }
        }
    }, [loading , res , toast])

    return [email, password, loading, onChangeEmail, onChangePassword, onSubmit, isPress]
}

export default LoginHook