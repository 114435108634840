import { Flex, Menu, MenuButton, Button,Text, MenuList, MenuOptionGroup, MenuItemOption, MenuDivider} from '@chakra-ui/react'
import React from 'react'
import {GrSort} from 'react-icons/gr'
const SearchResults = ({titleCount , onClick}) => {
    const clickMe = (key) => {
        localStorage.setItem("sortType", key)
        onClick()
    }
  return (
    <Flex w='100%' justifyContent={'space-between'} alignItems={'center'} >
        <Text fontSize={{sm:'25px' , base:'20px'}} fontWeight={'bold'}>{titleCount}</Text>
        <Menu >
            <MenuButton as={Button} colorScheme='yellow'>
                <Flex alignItems={'center'}>
                <Text mr={'10px'}>Sort</Text>
                <GrSort/>
                </Flex>
            </MenuButton>
            <MenuList>
            
            <MenuItemOption onClick={() => clickMe("")}> no order</MenuItemOption>

            <MenuDivider />

                    <MenuItemOption onClick={() => clickMe("الاكثر مبيعا")}>top seller</MenuItemOption>
                    <MenuItemOption onClick={() => clickMe("الاعلي تقييما")}>top rated</MenuItemOption>
                
                <MenuDivider />
              
                    <MenuItemOption onClick={() => clickMe("السعر من الاقل للاعلي")}>lower to highest</MenuItemOption>
                    <MenuItemOption onClick={() => clickMe("السعر من الاعلي للاقل")}>highest to lower</MenuItemOption>
               
            </MenuList>
        </Menu>
    </Flex>
  )
}

export default SearchResults