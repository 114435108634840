import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import RegisterHook from '../../hook/auth/register-hook'

const SignUp = () => {
    const [show, setShow] = useState(false)
    const [name, email, phone, password, confirmPassword, loading, onChangeName, onChangeEmail, onChangePhone, onChangePassword, onChangeConfirmPassword, OnSubmit] = RegisterHook();
  return (
    <form style={{width:"100%"}}>
    <VStack>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>User Name</FormLabel>
        <Input 
        value={name}
        onChange={onChangeName}
        type='text' 
        placeholder='Enter Your Name'
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>Email address</FormLabel>
        <Input 
        value={email}
        onChange={onChangeEmail}
        type='email' 
        placeholder='Enter Your Email'
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}> Phone</FormLabel>
        <Input 
        value={phone}
        onChange={onChangePhone}
        type='text' 
        placeholder='Enter Your number'
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>Password</FormLabel>
        <InputGroup>
        <Input
        value={password}
        onChange={onChangePassword}
        type={show?'text' : "password"} 
        placeholder='Enter Your Password'
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
            <InputRightElement>
                <Button onClick={()=>{setShow(!show)}}>{show?<ViewOffIcon/>: <ViewIcon/>}</Button>
            </InputRightElement>
        </InputGroup>
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>Confirm Password</FormLabel>
      
        <Input 
        value={confirmPassword}
        onChange={onChangeConfirmPassword}
        type={show?'text' : "password"} 
        placeholder='Confirm  Password'
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        mb="20px"
        />
          
    
        </FormControl>
        
        <Button w={'100%'} type='submit' colorScheme={'yellow'} onClick={OnSubmit} >Sign Up</Button>
    </VStack>
    </form>
  )
}

export default SignUp