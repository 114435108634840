import { Box, Button, FormControl,  Heading, Image, Input, SimpleGrid, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import avatar from '../../images/avatar-removebg-preview.png'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { addCategory, deleteCategory, getAllCategory } from '../../redux/actions/cateogryAction'
const AddCategory = () => {
  const toast = useToast()
  const [img, setImg] = useState(avatar)
  const [name, setName] = useState('')
  const [nameEn, setNameEn] = useState('')
const [loading, setLoading] = useState(true)
const [loadingD, setLoadingD] = useState(true)
const [selectedFile, setSelectedFile] = useState(null)


const res = useSelector(state => state.allCategory.category)
const resCreate = useSelector(state => state.allCategory.createCategory)

const handleDelete = (id)=>{ 
  dispatch(deleteCategory(id))
  setLoadingD(false)
}


const onNameChange = (e)=>{
  setName(e.target.value)
}

const onNameChangeEn = (e)=>{
  setNameEn(e.target.value)
}

const dispatch = useDispatch()
useEffect(() => {
  dispatch(getAllCategory())
  setLoadingD(true)
}, [ loadingD , loading , resCreate])

const onImageChange = (event) =>{
  if(event.target.files && event.target.files[0]){
    setImg( URL.createObjectURL(event.target.files[0]))
    setSelectedFile(event.target.files[0])
  }
}
const handleSubmit = async(e)=>{
  e.preventDefault();

  // Create a new FormData instance
  const formData = new FormData();

  // Append selected file to FormData
  formData.append('image', selectedFile);

  // Create JSON objects for 'ar' and 'en' fields
 

  // Convert objects to JSON strings and append to FormData
  formData.append('ar[name]', name); // assuming 'name' contains Arabic name
  formData.append('en[name]', nameEn);

  if(img === avatar){
      toast({
          title:'please add image ',
          status:'error',
          duration:3000,
          isClosable:true 
          })
          
  }else{
      await dispatch(addCategory(formData ))
      setLoading(false)
  }
  
  
}

useEffect(() => {
  if(loading===false){
    if(resCreate.status === 201){
      setImg(avatar)
      setName('')
      setSelectedFile(null)
      setLoading(true)
      toast({
        title:'Category added successfully',
        status:'success',
        duration:3000,
        isClosable:true 
        })
       
    
    }else if(resCreate.response.status === 400){
      toast({
        title:'  fill fields',
        status:'error',
        duration:3000,
        isClosable:true
      })
    }else{
      console.log(res)
      toast({
        title:"error",
        status:'error',
        duration:3000,
        isClosable:true
      })
    }
  }
  
}, [resCreate,loading , toast , selectedFile])
  return (
    <Box>
        <Heading mb='20px'>Add New Category</Heading>
        <form >
        <FormControl isRequired>
        
        <label htmlFor="upload-photo">
        <Image mb='30px' src={img} cursor={'pointer'} w={{md:'260px', base:"210"}}   h={{md:'200px' ,base:"150px"}}/>
        </label>
        <Input w='0' variant={'unstyled'} type="file" name='photo' onChange={onImageChange} id='upload-photo'/>
       
        </FormControl>
        <Tabs
         variant='soft-rounded' colorScheme='green'>
  <TabList>
    <Tab>ِAR</Tab>
    <Tab>EN</Tab>
  </TabList>
  <TabPanels>
    <TabPanel px={'0px'}>
    <FormControl isRequired>
          
        <Input 
        onChange={onNameChange}
        value={name}
        placeholder="name"
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        mb="20px"/>
        </FormControl>
    </TabPanel>
    <TabPanel px={'0px'}>
    <FormControl isRequired>
          
          <Input 
          onChange={onNameChangeEn}
          value={nameEn}
          placeholder="name english  "
          w='100%'
          variant={'unstyled'}
          border={'3px solid rgba(0,0,0,0.1)'}
          p='4.5px 16px'
          fontSize={'17px'}
          fontWeight={'semibold'}
          opacity='.8'
          mb="20px"/>
          </FormControl>
    </TabPanel>
  </TabPanels>
</Tabs>
        
        <Button onClick={handleSubmit} type='submit' colorScheme={'yellow'}>Save</Button>
        </form>

        <VStack w='100%' spacing={'40px'}  mb='60px'>
    <Text w='100%' fontSize={'28px'} fontWeight={'semibold'} mt='50px' mb='20px'>All Categories</Text>
    {
      <SimpleGrid columns={{xl:[6] ,lg:[5] , md:[4] , sm:[3] , base:[2] }} w='100%' spacing={'60px'} >
    {res?.data?.length> 0 ?(res.data.map(category=>(
      <VStack key={category._id} alignItems='center' justifyContent={'center'} _hover={{transform:'scale(1.1)'}} transition='ease-out all .5s'>
      <Box 
       alignItems='center' justifyContent={'center'} 
      boxShadow={'0px 0px 7px rgba(0,0,0,0.4)'}
      w={{base:'120px'}}
      h={{base:'120px'}}
      backgroundColor={'transparent'} 
      p='15px' 
      display={'flex'} 
      position={'relative'}
      flexDir={'column'}
      mb='5px'
      >
        <Box position={'absolute'} top={'4px'} left={'7px'} onClick={()=>handleDelete(category._id)}><SmallCloseIcon cursor={'pointer'} /></Box>
      <Image src={category.image} w={'80px'} h='80px'/>
      </Box> 
      
      <Text fontSize={'20px'} fontWeight={'semibold'}>{category.name}</Text>
  </VStack>
    ))):(<Text>no data</Text>)}
    </SimpleGrid>
    }
    

    </VStack>
    </Box>
  )
}

export default AddCategory