import { Text, Box ,Flex,Circle, HStack, Button} from '@chakra-ui/react'
import React from 'react'
import {GiRoundStar} from 'react-icons/gi'
import ViewProductsDetalisHook from '../../hook/products/view-products-detalis-hook';
import { useParams } from 'react-router-dom';
import AddToCartHook from '../../hook/cart/add-to-cart-hook';
const ProductText = () => {

  const { id } = useParams();
  const [item, images, cat, brand] = ViewProductsDetalisHook(id);

  const [colorClick, indexColor, addToCartHandel] = AddToCartHook(id,item)
  return (
    <Box display={'flex'} flexDir={'column'} h='480px' w='100%' p={{sm:'3' , base:'0'}} gap={'12px'}>
        <Text fontSize={{lg:'30px', md:'26px' , sm:'25px' , base:'23px'}} fontWeight='semibold' opacity={'.8'}>{item.title}</Text>

        <Flex fontSize={'20px'} fontWeight={'bold'} alignItems={'center'} color={'yellow.400'}>{item.ratingsAverage ? item.ratingsAverage : "0"}<GiRoundStar/></Flex>
        <Box  lineHeight={'60px'}>
        <Flex><Text opacity={'.6'} fontSize={'20px'} fontWeight='semibold' mr={'10px'}>Brand:</Text><Text fontSize={'22px'} fontWeight='bold'>{brand.name}</Text></Flex>
        <HStack spacing={'10px'}>
        {
            item.availableColors ? (item.availableColors.map((color, index) => {
              return (<Circle
                key={index}
                w='40px' h='40px'
                boxShadow={'0 0 7px rgba(0,0,0,0.8)'}
                onClick={() => colorClick(index, color)}
                bgColor={color}
               
                border={indexColor === index ? '3px solid black' : 'none'}
                ></Circle>)
            })) : null
          }
           
            </HStack>
        </Box>
        <Box lineHeight={'25px'} mt='30px'>
            <Text mb='10px'  fontSize={'25px'} fontWeight='bold'>Description</Text>
            <Text opacity='.7' fontWeight={'medium'} >{item.description}
            </Text>
        </Box>
        <HStack mt='25px'>
            <Button variant={'unstyled'} fontSize={'20px'}>{item.price}$</Button>
            <Button onClick={addToCartHandel} >Add To Cart</Button>
        </HStack>
    </Box>
  )
}

export default ProductText