import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import {GrFormClose} from 'react-icons/gr'
import { Link } from 'react-router-dom'
function NavBurger({onClose, isOpen}) {
  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
          <DrawerHeader borderBottomWidth='1px'>
            <Flex  alignItems={'center'} justifyContent='space-between'>
                <Link to='/login' className='link'>
                <Text onClick={onClose}>Login</Text>
                </Link>
                <GrFormClose onClick={onClose}/>
            </Flex>
            </DrawerHeader>
          <DrawerBody>
            <VStack spacing={'30px'} fontSize={'18px'} fontWeight={'600'} display='start'>
            <Text>Home</Text>
            </VStack>
            </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default NavBurger