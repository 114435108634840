import { ADD_BRAND, DELETE_BRAND, GET_ALL_BRAND, GET_ERROR, GET_ONE_BRAND } from "../types"


const initial = {
    brand : [],
    deleteBrand:[],
    createBrand:[],
    loading : true
}

const brandReducer = (state=initial , action)=>{
    switch(action.type){
        case GET_ALL_BRAND :
            return {
                ...state,
                brand : action.payload,
                loading : false
            }

        case GET_ONE_BRAND:
                return {
                    oneBrand: action.payload,
                    loading: false,
                }
        
        case ADD_BRAND :
            return {
                createBrand : action.payload,
                loading: false
            }

        case DELETE_BRAND:  
        return {
            deleteBrand : action.payload,
            loading: false
        }  

        case GET_ERROR :
            return {
                error : action.payload,
                loading: true
            }


        default : return state
    }
}

export default brandReducer