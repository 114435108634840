import { Box, Checkbox,  Flex,  Input,  Text, VStack } from '@chakra-ui/react'
import React from 'react'
import SidebarSearchHook from '../../hook/search/sidebar-search-hook';

const Filter = () => {
  const [category, brand, clickCategory, clickBrand, priceFrom, priceTo] = SidebarSearchHook();
  let localFrom = localStorage.getItem("priceFrom")
  let localTo = localStorage.getItem("priceTo")
  return (
    <VStack w='100%'>
        <Box w='100%'>
        <Text fontSize={'19px'}>Categories</Text>
        <Flex flexDir={'column'} p='3'>
        <Checkbox onChange={clickCategory} value={"0"} size={'lg'} colorScheme={'yellow'}>All</Checkbox>
        {
            category ? (category.map((item, index) => {
              return (
                  <Checkbox size={'lg'} value={item._id} onChange={clickCategory} colorScheme={'yellow'} key={index}>{item.name}</Checkbox>

              )
            })) : <h6> no data product found </h6>
          }
        
        </Flex>
        </Box>

        <Box w='100%'>
        <Text fontSize={'19px'}>Brand</Text>
        <Flex flexDir={'column'} p='3'>
        <Checkbox onChange={clickBrand} size={'lg'} colorScheme={'yellow'}>All</Checkbox>
        {
            brand ? (brand.map((item, index) => {
              return (
                <Checkbox value={item._id} key={index} onChange={clickBrand} size={'lg'} colorScheme={'yellow'}>{item.name}</Checkbox>
              )
            })) : <h6>لا يوجد ماركات</h6>
          }
        
        
        </Flex>
        </Box>

        <Box w='100%'>
        <Text  fontSize={'19px'}>Price</Text>
        <Box p='3'>
        <Flex alignItems={'center'} mb='7px'>
            <Text mr='7px'>from</Text>
            <Input
            value={localFrom}
            onChange={priceFrom}
             type={'number'}
             w='70px' 
             h='30px'/>
        </Flex>
        <Flex alignItems={'center'}>
            <Text mr='25px'>to</Text>
            <Input
            onChange={priceTo}
            value={localTo} 
            type='number' 
            w='70px' 
            h='30px'/>
        </Flex>
        </Box>
        </Box>
        
    </VStack>
  )
}

export default Filter