import { Box, SimpleGrid, VStack } from '@chakra-ui/react'
import React from 'react'
import CategoryHeader from '../../components/category/CategoryHeader'
import ProductCard from '../../components/product/ProductCard'
import Filter from '../../components/Utils/Filter'
import SearchResults from '../../components/Utils/SearchResults'
import ViewSearchProductsHook from '../../hook/products/view-search-products-hook'
import CardContainerHook from '../../hook/products/card-container-hook'
import Pagination from '../../components/Utils/Pagination'


const ProductShop = () => {
  const [favProd] = CardContainerHook()
  const [items, pagination, onPress, getProduct, results] = ViewSearchProductsHook();

  console.log({results})
    if (pagination)
        var pageCount = pagination;
    else
        pageCount = 0;

  return (
    <VStack>
        {/* <CategoryHeader/> */}
        <SearchResults onClick={getProduct} titleCount={`${results} Products`}/>
        <Box display='flex' justifyContent={'space-between'} w='100%' py='20px'>
          <Box mr='20px' display={{base:'none' , sm:'block'}}>
            <Filter/>
          </Box>
          <Box w={{md:'75%', sm:'50%'}}>
          <SimpleGrid columns={{xl:[4] , lg:[3] , md :[2] , sm:[1] , base:[1]}} spacing={'36px'} w='100%'>
         
            {
                    items ? (
                        items.data?.map((item, index) => <ProductCard favProd={favProd} key={index} item={item} />)
                    ) : null

                }
          </SimpleGrid>
          </Box>
        </Box>
        <Pagination pageCount={pageCount} onPage={onPress} />
    </VStack>
  )
}

export default ProductShop