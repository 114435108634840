import { SimpleGrid, Spinner, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import BrandCard from '../../components/Brand/BrandCard'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBrand } from '../../redux/actions/brandAction'
import Pagination from '../../components/Utils/Pagination'

const AllBrands = () => {

  const dispatch = useDispatch()


  const res = useSelector(state=> state.allBrand.brand )
  const loading = useSelector(state=>state.allBrand.loading)
console.log(res)
  let pageCount = 0 
  if(res.paginationResult){
    pageCount = res.paginationResult.numberOfPages


  }

  const onPage = (num)=>{
    dispatch(getAllBrand(8,num))
  }

  useEffect(() => {
    dispatch(getAllBrand(8))
  }, [dispatch])
  
  return (
    <VStack mb='60px'>
        <Text w='100%' fontSize={'28px'} fontWeight={'semibold'} mb='40px'>All Brands</Text>
        {
          loading ? (
            <Spinner size='lg'/>
          ):(
            <SimpleGrid columns={{xl:[4] , lg:[4] , md :[3] , sm:[2] , base:[2]}} spacing={'36px'} w='100%'>
              {
                res.data.length > 0 ? (
                  res.data.map(e=>(
                    <BrandCard key={e._id} img={e.image}/>
                  ))
                ):(
                  <Text w='100%' fontSize={'18px'} fontWeight={'semibold'}>No Brand Found</Text>
                )
              }
        
            </SimpleGrid>
          )
        }
        {
          pageCount > 1 ?(
            <Pagination pageCount={pageCount} onPage={onPage}/>
          ):null
        }
        
    </VStack>
  )
}

export default AllBrands