import { Box, Button, FormControl, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useColorMode, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ApplayCouponHook from '../../hook/cart/applay-coupon-hook'
import DeleteCartHook from '../../hook/cart/delete-cart-hook'
import ViewAddressesHook from '../../hook/user/view-addresses-hook'
import { useDispatch, useSelector } from 'react-redux'
import { getOneUserAddress } from '../../redux/actions/userAddressesAction'
import { createOrder } from '../../redux/actions/orderAction'

const CartCheckout = ({ totalCartPrice, totalCartPriceAfterDiscount, couponNameRes , id }) => {
  const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [addressId, setAddressID] = useState("0")
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        const get = async () => {
          
            await dispatch(getOneUserAddress(addressId))
        }
      get()
    }, [addressId])
      
    const resAddress = useSelector(state => state.userAddressesReducer.oneAddress)
    const resOrder = useSelector(state => state?.orderReducer?.createOrder)

    const handleSubmit = async()=>{

        if(addressId !=="0"){
          const shippingAddress = {...resAddress.data}

         
          await dispatch(createOrder( id , {shippingAddress}))

          setLoading(false)
        }else{
          toast({
            title:'please choose address',
            status:'error',
            duration:3000,
            isClosable:true 
            })
        }
    }  
    
    useEffect(() => {
      if(loading == false){
        console.log({resOrder})
          if(resOrder?.status == 201){
            toast({
              title:'order completed successfully',
              status:'success',
              duration:3000,
              isClosable:true 
              })
              setTimeout(() => {
                window.location.href = "/user/allorders"
            }, 1000);
          }
      }
    }, [loading , resOrder])
    

    const {colorMode}=useColorMode()
    const isDark = colorMode ==='dark'
    const [handelDeleteCart] = DeleteCartHook()

    const [couponName, onChangeCoupon, handelSubmitCoupon] = ApplayCouponHook();

    const [res] = ViewAddressesHook()

    useEffect(() => {
        if (couponNameRes) {
            onChangeCoupon(couponNameRes)
        }
    }, [couponNameRes])
  return (
   <Box borderRadius={'5px'} p='4' boxShadow={'0 0 5px rgba(0,0,0,0.2)'} mb={{base:'20px' , md:'0'}}>
<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Choose Address </ModalHeader>
          <ModalCloseButton />
          <ModalBody gap={'2px'}>
          <Select onChange={(e)=>setAddressID(e.target.value)} opacity='.8'  _focus={{border:'none'}} variant={'filled'}>
        <option value="0">  choose address</option>
        {
                            res.data ? (res.data.map((item, index) => {
                                return (
                                    <option key={index} value={item._id}>{item.alias}</option>
                                )
                            })) : null

                        }
        </Select>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              Back
            </Button>
            <Button onClick={handleSubmit}  variant='ghost'> Go</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>





        <FormControl mb='10px'>
            <InputGroup  display={'flex'} alignItems='center'>
            <Input  
            value={couponName}
            onChange={(e) => onChangeCoupon(e.target.value)}  
            type='text' 
            variant={'unstyled'} 
            border={'3px solid #ECC94B'} 
            p='4.5px 16px' 
            fontWeight={'semibold'}
            opacity='.8'
            />
            <InputRightElement>
                <Button h='91%' onClick={handelSubmitCoupon} colorScheme={'yellow'}>Go</Button>
            </InputRightElement>
            </InputGroup>
        </FormControl>
        <Text color={isDark?'#ECC94B':'rgba(0,0,0,1)'} borderRadius={'5px'} textAlign={'center'} p='3' border={'4px solid rgba(0,0,0,0.2)'} w='100%' fontWeight={'500'}>
        {
                        totalCartPriceAfterDiscount >= 1 ?
                            `${totalCartPrice}$  after discount   ${totalCartPriceAfterDiscount} ` :
                            `${totalCartPrice} `
                    }
          
        
          $
          
          </Text>
      <Button onClick={onOpen} mt='40px' p='3' w='100%' colorScheme={'yellow'} >Checkout</Button>
   </Box>
  )
}

export default CartCheckout