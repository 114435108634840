import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay,  Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

function UserSideBarSmallScreen({isOpen,onClose}) {
  return (
    <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={'yellow.400'}>Settings</DrawerHeader>
          <DrawerBody>
          <VStack>
        <Link to='/user/allorders' className='link' style={{width:'100%'}} >
            <Text  borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Order Management</Text>
        </Link>
        <Link to='/user/favoriteproducts' className='link' style={{width:'100%'}} >
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Favorite Products</Text>
        </Link>
        <Link to='/user/address' className='link' style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4'  fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Address</Text>
        </Link>
        <Link to='/user/profile' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Profile</Text>
        </Link>
    </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
  )
}

export default UserSideBarSmallScreen