import { CANCEL_ORDER, COMPLETE_ORDER, CREATE_ORDER_CASH, GET_ALL_ORDER, GET_ERROR, GET_ONE_ORDER } from "../types";



const initial = {
    createOrder:[],
    getAllOrder:[],
    getOneOrder:[],
    completeOrder:[],
    cancelOrder:[],
    loading:true
}


const orderReducer = (state = initial , action )=>{
        switch (action.type) {
            case CREATE_ORDER_CASH:
                return{
                   ...state,
                   createOrder : action.payload         
            }

            case GET_ALL_ORDER:
                return{
                   ...state,
                   getAllOrder : action.payload         
            }

            case GET_ONE_ORDER:
                return{
                   ...state,
                   getOneOrder : action.payload         
            }

            case COMPLETE_ORDER:
                return{
                   ...state,
                   completeOrder : action.payload         
            }

            case CANCEL_ORDER:
                return{
                   ...state,
                   cancelOrder : action.payload         
            }


            case GET_ERROR :
            return {
                    error:action.payload,
                    loading:true
            }

            default:
                return state;
        
        }
}


export default orderReducer