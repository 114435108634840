import { Box, Button, Flex, Text, Textarea } from '@chakra-ui/react'
import React from 'react'
import ReactStars from 'react-rating-stars-component'
import {BsStarFill, BsStarHalf , BsStar} from 'react-icons/bs'
import AddRateHook from '../../hook/review/add-rate-hook'
import { useParams } from 'react-router-dom'

const RatePost = () => {
    const {id} =useParams() ;
  const [OnChangeRateText, OnChangeRateValue, rateText, rateValue, user, onSubmit] = AddRateHook(id)
  var name = ""
  if (user) name = user.name

    const setting ={
        size:20,
        count:5,
        color:"#ECC94B",
        activeColor:"#ECC94B",
        value:rateValue,
        isHalf:true,
        ally:true,
        emptyIcon:<BsStar/>,
        halfIcon:<BsStarHalf/>,
        filledIcon:<BsStarFill/>,
        onChange:newValue=>{
            OnChangeRateValue(newValue)
     
        }
    }
  return (
    <Box w='100%'>
        <Flex alignItems={'center'} mb='10px'>
            <Text fontSize={'22px'} mr='4px' fontWeight={'semibold'}>{name}</Text>
            <ReactStars {...setting}/>
        </Flex>
        <Textarea
        value={rateText}
        onChange={OnChangeRateText}
        placeholder='Write Your Comment ...'
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='6px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        mb='10px'
        />
        <Button onClick={onSubmit} colorScheme={'yellow'}>Add</Button>
    </Box>
  )
}

export default RatePost