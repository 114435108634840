
import React, { useEffect, useState } from 'react'
import avatar from '../../images/avatar-removebg-preview.png'
import { Box, Button, Flex, FormControl,  Heading, Image, Input, SimpleGrid, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack, useToast } from '@chakra-ui/react'


import { useDispatch, useSelector } from 'react-redux'
import { addPromo, deletePromo, getAllPromo } from '../../redux/actions/promoAction'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { addPromoTop, deletePromoTop, getAllPromoTop } from '../../redux/actions/promoTopAction'

const AddPromo = () => {
    const toast = useToast()
  const [img, setImg] = useState(avatar)
  const [name, setName] = useState('')
  const [nameEn, setNameEn] = useState('')
  const [nameTop, setNameTop] = useState('')
  const [nameTopEn, setNameTopEn] = useState('')
const [loading, setLoading] = useState(true)
const [loadingD, setLoadingD] = useState(true)
const [loadingTop, setLoadingTop] = useState(true)
const [loadingDTop, setLoadingDTop] = useState(true)
const [selectedFile, setSelectedFile] = useState(null)



const res = useSelector(state => state.promoReducer.promo)
const resTop = useSelector(state => state.promoTopReducer.promoTop)


const resCreate = useSelector(state => state.promoReducer.createPromo)
const resCreateTop = useSelector(state => state.promoTopReducer.createPromoTop)

const handleDelete = (id)=>{ 
  dispatch(deletePromo(id))
  setLoadingD(false)
}

const handleDeleteTop = (id)=>{ 
    dispatch(deletePromoTop(id))
    setLoadingDTop(false)
  }


const onNameChange = (e)=>{
  setName(e.target.value)
}

const onNameChangeEn = (e)=>{
  setNameEn(e.target.value)
}

const onNameChangeTop = (e)=>{
    setNameTop(e.target.value)
  }

const onNameChangeTopEn = (e)=>{
    setNameTopEn(e.target.value)
}

const dispatch = useDispatch()
useEffect(() => {
  dispatch(getAllPromo())
  setLoadingD(true)
}, [ loadingD , loading , resCreate])

useEffect(() => {
    dispatch(getAllPromoTop())
    setLoadingDTop(true)
  }, [ loadingDTop , loadingTop , resCreateTop])

const onImageChange = (event) =>{
  if(event.target.files && event.target.files[0]){
    setImg( URL.createObjectURL(event.target.files[0]))
    setSelectedFile(event.target.files[0])
  }
}
const handleSubmit = async (e) => {
  e.preventDefault();

  // Create a new FormData instance
  const formData = new FormData();

  // Append selected file to FormData
  formData.append('image', selectedFile);

  // Create JSON objects for 'ar' and 'en' fields
 

  // Convert objects to JSON strings and append to FormData
  formData.append('ar[name]', name); // assuming 'name' contains Arabic name
  formData.append('en[name]', nameEn);

  // Check if image is not uploaded
  if (img === avatar) {
    toast({
      title: 'Please add an image',
      status: 'error',
      duration: 3000,
      isClosable: true 
    });
  } else {
    try {
      // Dispatch addPromo action with FormData
      await dispatch(addPromo(formData));
      setLoading(false);
    } catch (error) {
      // Handle dispatch error
      console.error('Error adding promo:', error);
      // Optionally, show an error toast to the user
      toast({
        title: 'Failed to add promo',
        status: 'error',
        duration: 3000,
        isClosable: true 
      });
    }
  }
};


const handleSubmitTop = async(e)=>{
    e.preventDefault()
   if(nameTop === '' || nameTopEn === ''){
    toast({
      title:'  please fill fields title',
      status:'error',
      duration:3000,
      isClosable:true 
      })
   }else{
const data = {
  ar:{
    name:nameTop
  },
  en:{
    name:nameTopEn
  }
}
    await dispatch(addPromoTop(data))
    setLoadingTop(false)
   }
    
   
    
    
  }

useEffect(() => {
  if(loading===false){
    if(resCreate.status === 201){
      setImg(avatar)
      setName('')
      setSelectedFile(null)
      setLoading(true)
      toast({
        title:'promo added successfully',
        status:'success',
        duration:3000,
        isClosable:true 
        })
       
    
    }else if(resCreate.response.status === 400){
      toast({
        title:'  fill fields',
        status:'error',
        duration:3000,
        isClosable:true
      })
    }else{
      console.log(res)
      toast({
        title:"error",
        status:'error',
        duration:3000,
        isClosable:true
      })
    }
  }
  
}, [resCreate,loading , toast , selectedFile])


useEffect(() => {
    if(loadingTop===false){
      if(resCreateTop.status === 201){
    
        setNameTop('')
      
        setLoadingTop(true)
        toast({
          title:'promo added successfully',
          status:'success',
          duration:3000,
          isClosable:true 
          })
         
      
      }else if(resCreate.response.status === 400){
        toast({
          title:'  fill fields',
          status:'error',
          duration:3000,
          isClosable:true
        })
      }else{
       
        toast({
          title:"error",
          status:'error',
          duration:3000,
          isClosable:true
        })
      }
    }
    
  }, [resCreateTop,loadingTop , toast  ])

  return (
    <Box>
        
        <Heading mb='20px'>Add Promo </Heading>
        {
            res?.data?.length > 0 ?
            (null): (
                <form >
        <FormControl isRequired>
        
        <label htmlFor="upload-photo">
        <Image mb='30px' src={img} cursor={'pointer'} w={{md:'260px', base:"210"}}   h={{md:'200px' ,base:"150px"}}/>
        </label>
        <Input w='0' variant={'unstyled'} type="file" name='photo' onChange={onImageChange} id='upload-photo'/>
       
        </FormControl>
        <Tabs variant='soft-rounded' colorScheme='green'>
  <TabList>
    <Tab>ِAR</Tab>
    <Tab>EN</Tab>
  </TabList>
  <TabPanels>
    <TabPanel px={'0px'}>
    <FormControl isRequired>
          
          <Input 
          onChange={onNameChange}
          value={name}
          placeholder="promo name arabic"
          w='100%'
          variant={'unstyled'}
          border={'3px solid rgba(0,0,0,0.1)'}
          p='4.5px 16px'
          fontSize={'17px'}
          fontWeight={'semibold'}
          opacity='.8'
          mb="20px"/>
          </FormControl>
    </TabPanel>
    <TabPanel px={'0px'}>
    <FormControl isRequired>
          
          <Input 
          onChange={onNameChangeEn}
          value={nameEn}
          placeholder="promo name english"
          w='100%'
          variant={'unstyled'}
          border={'3px solid rgba(0,0,0,0.1)'}
          p='4.5px 16px'
          fontSize={'17px'}
          fontWeight={'semibold'}
          opacity='.8'
          mb="20px"/>
          </FormControl>
    </TabPanel>
  </TabPanels>
</Tabs>
        <Button onClick={handleSubmit} type='submit' colorScheme={'yellow'}>Save</Button>
        </form>
            )
        }
        

        <VStack w='100%' spacing={'40px'}  mb='60px'>
    <Text w='100%' fontSize={'28px'} fontWeight={'semibold'} mt='50px' mb='20px'>Promo</Text>
  
     
    {res?.data?.length> 0 ?(res.data.map(category=>(
      <VStack w="100%" key={category._id} alignItems='center' justifyContent={'center'} _hover={{transform:'scale(1.1)'}} transition='ease-out all .5s'>
      <Flex
        w="100%"
       alignItems='center' justifyContent={'space-between'} 
      boxShadow={'0px 0px 7px rgba(0,0,0,0.4)'}
     
      h={{base:'150px'}}
      backgroundColor={'transparent'} 
      p='15px' 
      display={'flex'} 
      position={'relative'}
      
      mb='5px'
      >
        <Box position={'absolute'} top={'4px'} left={'7px'} onClick={()=>handleDelete(category._id)}><SmallCloseIcon cursor={'pointer'} /></Box>
      <Image src={category.image} w={'80px'} h='80px'/>
      <Text fontSize={'20px'} fontWeight={'semibold'}>{category.name}</Text>
      </Flex> 
      
  </VStack>
    ))):(<Text>no data</Text>)}
  
   
  <Text w='100%' fontSize={'28px'} fontWeight={'semibold'} mt='50px' mb='20px'>Promo Top</Text>
    
  {
            resTop?.data?.length > 0 ?
            (null): (
                <form style={{width : "100%"}} >
      <Tabs variant='soft-rounded' colorScheme='green'>
  <TabList>
    <Tab>ِAR</Tab>
    <Tab>EN</Tab>
  </TabList>
  <TabPanels>
    <TabPanel px={'0px'}>
    <FormControl isRequired>
          
          <Input 
          onChange={onNameChangeTop}
          value={nameTop}
          placeholder="promoTop name arabic"
          w='100%'
          variant={'unstyled'}
          border={'3px solid rgba(0,0,0,0.1)'}
          p='4.5px 16px'
          fontSize={'17px'}
          fontWeight={'semibold'}
          opacity='.8'
          mb="20px"/>
          </FormControl>
    </TabPanel>
    <TabPanel px={'0px'}>
    <FormControl isRequired>
          
          <Input 
          onChange={onNameChangeTopEn}
          value={nameTopEn}
          placeholder="promoTop name english"
          w='100%'
          variant={'unstyled'}
          border={'3px solid rgba(0,0,0,0.1)'}
          p='4.5px 16px'
          fontSize={'17px'}
          fontWeight={'semibold'}
          opacity='.8'
          mb="20px"/>
          </FormControl>
    </TabPanel>
  </TabPanels>
</Tabs>
        
        <Button onClick={handleSubmitTop} type='submit' colorScheme={'yellow'}>Save</Button>
        </form>
            )
        }

{resTop?.data?.length> 0 ?(resTop.data.map(category=>(
      <VStack w="100%" key={category._id} alignItems='center' _hover={{transform:'scale(1.1)'}} transition='ease-out all .5s'>
      <Flex
        w="100%"
       alignItems='center' justifyContent={'space-between'} 
      boxShadow={'0px 0px 7px rgba(0,0,0,0.4)'}
     
      h={{base:'150px'}}
      backgroundColor={'transparent'} 
      p='15px' 
      display={'flex'} 
      position={'relative'}
      
      mb='5px'
      >
        <Box position={'absolute'} top={'4px'} left={'7px'} onClick={()=>handleDeleteTop(category._id)}><SmallCloseIcon cursor={'pointer'} /></Box>
      
      <Text fontSize={'20px'} fontWeight={'semibold'}>{category.name}</Text>
      </Flex> 
      
  </VStack>
    ))):(<Text>no data</Text>)}
        
    </VStack>
    </Box>
  )
}

export default AddPromo