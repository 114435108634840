import { Box, Heading, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AllOrdersItem from './AllOrdersItem'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrder } from '../../redux/actions/orderAction'

const AllOrders = () => {
  const res = useSelector(state => state.orderReducer.getAllOrder)
  
  let pending = []
  let completed = []
  let cancelled = []
  if(res){
     pending =res?.data?.filter(e=> e?.isDelivered === false && e?.cancelled === false)
     completed =res?.data?.filter(e=> e?.isDelivered === true)
     cancelled =res?.data?.filter(e=> e?.cancelled === true)
   
  }
     const resCancel = useSelector(state => state.orderReducer.cancelOrder)
     const createOrder = useSelector(state => state.orderReducer.completeOrder)
  
    const dispatch = useDispatch()
    useEffect(() => {
      
           dispatch(getAllOrder())
    
    }, [dispatch , createOrder , resCancel])
  return (
    <Box>
   
        <Heading mb='20px'>All Orders</Heading>
        <Tabs size={{md : 'md' , base:"sm"}} variant='enclosed'>
    <TabList fontSize={"md"} >
      <Tab>pending</Tab>
      <Tab>completed</Tab>
      <Tab>cancelled</Tab>
    </TabList>
    
    <TabPanels>
      <TabPanel>

      { pending?.length >0 ? (
                pending.map(e=>(
                 
                  <AllOrdersItem key={e._id} item={e}/>

                ))

        ):"no pending order"}
        {
     
    }
      </TabPanel>
      <TabPanel>
      { completed?.length >0 ? (
                completed.map(e=>(
                 
                  <AllOrdersItem key={e._id} item={e}/>

                ))

        ):"  no completed order yet"}
        {
     
    }
      </TabPanel>
      <TabPanel>
      { cancelled?.length > 0 ? (
                cancelled.map(e=>(
                 
                  <AllOrdersItem key={e._id} item={e}/>

                ))

        ):"  no cancelled order yet"}
        {
     
    }
      </TabPanel>
    </TabPanels>
  </Tabs>
       
    </Box>
  )
}

export default AllOrders