import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import {AiOutlineHeart} from 'react-icons/ai'
import { AiFillHeart } from "react-icons/ai";
import {GiRoundStar} from 'react-icons/gi'
import { Link } from 'react-router-dom'
import ProductCardHook from '../../hook/products/product-card-hook'

const ProductCard = ({item , favProd}) => {
  const [removeToWishListData, addToWishListData, handelFav, favImg] = ProductCardHook(item, favProd)

  return (
    <VStack w="100%" boxShadow={'0 0 7px rgba(0,0,0,0.5)'} borderRadius='10px' p='3' _hover={{transform:'translateY(-5px)'}} transition={'ease-out all .3s'}>
      <Box w='100%' fontSize={'30px'}>
        {
          !favImg ? (<AiOutlineHeart onClick={handelFav} style={{color:'gray'}} />) : (<AiFillHeart onClick={handelFav} style={{color:'gray'}} />)
        }
      
      </Box>
      <Link to={`/product/${item._id}`}>
      <Image maxW={{sm:'150px' , base:'70px'}} h='150px'  src={item.imageCover}/>
      </Link>
      <Text w='100%' alignItems={'start'} opacity={'.8'} fontSize={'17px'} fontWeight={'semibold'}>{item.title}</Text>
      <Box display={'flex'} justifyContent={'space-between'} w='100%'>
      <Flex fontWeight={'bold'} alignItems={'center'} color={'yellow.400'}>{ item.ratingsAverage ? item.ratingsAverage : "0 " }<GiRoundStar/></Flex>
        <Text fontWeight={'bold'}>{item.price}$</Text>
      </Box>
    </VStack>
  )
}

export default ProductCard